import { Navigate } from 'react-router-dom';

export const DirectorRoute = ({
  component: Component,
  redirectTo = '/Home',
}) => {
  return localStorage.getItem('position') === '[ROLE_DIRECTOR]' ? (
    Component
  ) : (
    <Navigate to={redirectTo} />
  );
};
