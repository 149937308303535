import { createContext, useContext, useState } from 'react';
import { SETTINGS_API } from '../api/settingsPageApi';

const UserContext = createContext();

export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
  const [users, setUsers] = useState(null);
  const [logins, setLogins] = useState();

  const fetchUsers = async () => {
    const res = await SETTINGS_API.getAllUsers();
    if (res) {
      setUsers(res);
      const loginsFromResponse = res.map(item => item.login);
      setLogins(loginsFromResponse);
    }
  };

  return (
    <UserContext.Provider value={{ users, logins, fetchUsers }}>
      {children}
    </UserContext.Provider>
  );
};
