import { api } from './api';

export const SETTINGS_API = {
    async getAllUsers() {
        try {
            const { data } = await api.get('decimal/all');
            return data;
        } catch (error) {
            console.error(error);
        }
    },

    async addNewUser(newUser) {
        try {
            const { data } = await api.post('decimal/', newUser);
            return data
        } catch (error) {
            console.error(error);
        }
    },

    async getUserById(userId) {
        try {
            const { data } = await api.get(`decimal/${userId}`);
            return data
        } catch (error) {
            console.error(error);
        }
    },

    async updateUserById(userId, updatedUser) {
        try {
            await api.put(`decimal/${userId}`, updatedUser);
        } catch (error) {
            console.error(error);
        }
    },

    async deleteUserById(userId) {
        try {
            await api.delete(`decimal/${userId}`);
        } catch (error) {
            console.error(error);
        }
    },
};