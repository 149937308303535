import React from 'react';
import ReactDOM from 'react-dom/client';
import App from 'components/App'; // Обновленный импорт
import { BrowserRouter } from 'react-router-dom';
import { UserProvider } from 'hooks/userContext';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter>
    <UserProvider >
      <App />
    </UserProvider>
  </BrowserRouter>
);
