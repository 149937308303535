import axios from 'axios';
import CryptoJS from 'crypto-js';

const API_BASE_URL = 'https://crm.techkidsacademy.com.ua/back';
const SECRET_KEY = '123';

const decryptData = encryptedData => {
  const decryptedBytes = CryptoJS.AES.decrypt(encryptedData, SECRET_KEY);
  const decryptedData = JSON.parse(decryptedBytes.toString(CryptoJS.enc.Utf8));
  return decryptedData;
};

export const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'ngrok-skip-browser-warning': true
  }
});

api.interceptors.request.use(
  async (config) => {
    const storedData = localStorage.getItem('encryptedData');
    if (storedData) {
      const decryptedData = decryptData(storedData);
      config.headers.Authorization = `Bearer ${decryptedData}`;
    }

    return config;
  },
  (err) => {
    console.log(err);
    return Promise.reject(err);
  },
);

api.interceptors.response.use(
  (res) => res,
  (err) => {
    if (
      err.response &&
      err.response.status === 401
    ) {
      localStorage.removeItem('encryptedData');
      localStorage.removeItem('role');
      localStorage.removeItem('auto');
      window.location.reload();
    }
    return Promise.reject(err);
  },
);
