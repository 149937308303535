import { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import MainNavigation from '../../components/MainNavigation/MainNavigation'
import Loader from '../../components/Loader/Loader';

export const SharedLayout = () => {
  return (
    <>
      <MainNavigation />
      <main>
        <Suspense fallback={<Loader />}>
          <Outlet />
        </Suspense>
      </main>
    </>
  )
}
