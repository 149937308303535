// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Loader_overlay__vojGq {
  position: fixed;
  top: 0;
  left: 200px;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(10, 0, 255, 0.1);
  -webkit-backdrop-filter: blur(2px);
          backdrop-filter: blur(2px);
  z-index: 200;
}

.Loader_loader__\\+lRPl {
  border: 10px solid #f3f3f3;
  border-top: 10px solid #3498db;
  border-radius: 50%;
  width: 80px;
  height: 80px;
  animation: Loader_spin__E4RJ0 1s linear infinite;
}

@keyframes Loader_spin__E4RJ0 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Loader/Loader.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;EACf,MAAM;EACN,WAAW;EACX,QAAQ;EACR,SAAS;EACT,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iCAAiC;EACjC,kCAA0B;UAA1B,0BAA0B;EAC1B,YAAY;AACd;;AAEA;EACE,0BAA0B;EAC1B,8BAA8B;EAC9B,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,gDAAkC;AACpC;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".overlay {\n  position: fixed;\n  top: 0;\n  left: 200px;\n  right: 0;\n  bottom: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  background: rgba(10, 0, 255, 0.1);\n  backdrop-filter: blur(2px);\n  z-index: 200;\n}\n\n.loader {\n  border: 10px solid #f3f3f3;\n  border-top: 10px solid #3498db;\n  border-radius: 50%;\n  width: 80px;\n  height: 80px;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"overlay": `Loader_overlay__vojGq`,
	"loader": `Loader_loader__+lRPl`,
	"spin": `Loader_spin__E4RJ0`
};
export default ___CSS_LOADER_EXPORT___;
