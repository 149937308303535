import { NavLink } from 'react-router-dom';
import { api } from '../../api/api';
import css from './MainNavigation.module.css';

const handleExportClick = () => {
  const result = window.confirm('Ви дійсно хочете зробити експорт бази?');
  if (result) {
    api
      .get(`/clients/export`, {
        responseType: 'blob',
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'clients.xlsx');
        link.click();
      })
      .catch(error => {
        console.error('Error downloading file:', error);
      });
  }
};

const handleImportClick = () => {
  const fileInput = document.createElement('input');
  fileInput.type = 'file';
  fileInput.accept = '.xlsx'; // Обмеження на тип файлу (Excel)
  fileInput.style.display = 'none';
  fileInput.addEventListener('change', () => {
    const file = fileInput.files[0];
    if (file) {
      // Відправити файл на сервер для імпорту
      const formData = new FormData();
      formData.append('file', file);
      api
        .post('/clients/import', formData)
        .then(response => {
          // Обробити успішну відповідь від сервера (якщо потрібно)
          console.log('File uploaded successfully:', response.data);
          const result = window.confirm('Файл успішно завантажений!');
        })
        .catch(error => {
          // Обробити помилку від сервера (якщо потрібно)
          console.error('Error uploading file:', error);
          const result = window.confirm('Помилка під час завантаження файлу!');
        });
    }
  });
  document.body.appendChild(fileInput);
  fileInput.click();
};

function MainNavigation() {
  const handleOutClick = () => {
    const result = window.confirm('Ви дійсно хочете вийти?');
    if (result) {
      api
        .post(`/my-logout`)
        .then(response => {
          localStorage.removeItem('encryptedData');
          localStorage.removeItem('auto');
          localStorage.removeItem('role');
          localStorage.removeItem('position');
          window.location.reload();
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  return (
    <div className={css.MainNavigation}>
      <ul>
        <li>
          <NavLink
            to="/Home"
            className={({ isActive }) => (isActive ? css.active : undefined)}
          >
            Робочий стіл
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/deals"
            className={({ isActive }) => (isActive ? css.active : undefined)}
          >
            Угоди
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/tasks"
            className={({ isActive }) => (isActive ? css.active : undefined)}
          >
            Задачі
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/ClientBase"
            className={({ isActive }) => (isActive ? css.active : undefined)}
          >
            Списки
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/Analytics"
            className={({ isActive }) => (isActive ? css.active : undefined)}
          >
            Аналітика
          </NavLink>
        </li>
        {localStorage.getItem('position') === '[ROLE_DIRECTOR]' && (
          <li>
            <NavLink
              to="/settings"
              className={({ isActive }) => (isActive ? css.active : undefined)}
            >
              Налаштування
            </NavLink>
          </li>
        )}
      </ul>

      <div className={css.NavButtons}>
        {localStorage.getItem('position') === '[ROLE_DIRECTOR]' && (
          <button className={css.importButton} onClick={handleImportClick}>
            Імпорт бази
          </button>
        )}
        {localStorage.getItem('position') === '[ROLE_DIRECTOR]' && (
          <button className={css.exportButton} onClick={handleExportClick}>
            Експорт бази
          </button>
        )}
        <button className={css.LogoutButton} onClick={handleOutClick}>
          <span className={css.ButtonText}>Вийти</span>
          <span className={css.AnimationOverlay}></span>
        </button>
      </div>
    </div>
  );
}

export default MainNavigation;
