import { lazy, Suspense, useState, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';

import { SharedLayout } from '../pages/SharedLayout/SharedLayout';
import Loader from './Loader/Loader';
import { DirectorRoute } from 'HOCs/DirectorRoute';

import { useUser } from '../hooks/userContext';

const Home = lazy(() => import('pages/HomePage/HomePage'));
const LoginPage = lazy(() => import('pages/LoginPage/LoginPage'));
const ClientBase = lazy(() => import('pages/ClientBase/ClientsBasePage'));
const AnalyticsPage = lazy(() => import('pages/AnalyticsPage/AnalyticsPage'));
const TasksPage = lazy(() => import('pages/TasksPage/TasksPage'));
const ClientPage = lazy(() => import('pages/ClientPage/ClientPage'));
const DealsPage = lazy(() => import('pages/DealsPage/DealsPage'));
const SettingsPage = lazy(() => import('pages/SettingsPage/SettingsPage'));

const storedAuto = localStorage.getItem('auto') === 'true';

const App = () => {
  const [authorization, setAuthorization] = useState(storedAuto);
  const navigate = useNavigate();
  const { fetchUsers } = useUser();

  if (!authorization) {
    navigate('/login');
  }

  useEffect(() => {
    const position = localStorage.getItem('position');

    if (
      (authorization && position === '[ROLE_DIRECTOR]') ||
      position === '[ROLE_ROOT_ADMIN]'
    ) {
      fetchUsers();
    }
  }, [authorization]);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {authorization ? (
          <Route path="/" element={<SharedLayout />}>
            <Route index element={<ClientBase />} />
            <Route path="ClientBase" element={<ClientBase />} />
            <Route
              path="ClientBase/:clientId"
              element={<ClientPage setAuthorization={setAuthorization} />}
            />
            <Route
              path="Home"
              element={<Home setAuthorization={setAuthorization} />}
            />
            <Route
              path="deals"
              element={<DealsPage setAuthorization={setAuthorization} />}
            />
            <Route
              path="tasks"
              element={<TasksPage setAuthorization={setAuthorization} />}
            />
            <Route
              path="Analytics"
              element={<AnalyticsPage setAuthorization={setAuthorization} />}
            />
            <Route
              path="settings"
              element={
                <DirectorRoute
                  redirectTo="/Home"
                  component={<SettingsPage />}
                />
              }
            />
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        ) : (
          <>
            <Route
              path="/*"
              element={<LoginPage setAuthorization={setAuthorization} />}
            />
          </>
        )}
      </Routes>
    </Suspense>
  );
};

export default App;
